import { useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Alert from 'react-bootstrap/Alert'
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './emtcclogo.png';
import rocrt from './RocrtTransIcon.png';
import sizingOne from './SizingOne.png';
import sizingTwo from './SizingTwo.png';
import './App.css';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const server = window.location.hostname === 'localhost' ? 'http://localhost:4242/dev' : 'https://v7872rxuhk.execute-api.ap-southeast-2.amazonaws.com/dev';

const products = [
  {
    id: 'K1Rpg4S2o5Ql3e',
    name: 'Limited Overs Playing Shirt (SS)',
    img: 'png',
    desc: 'Short sleeved navy and red limited overs playing shirt, worn by the First XI in 40 over and T20 formats',
    price: 5500,
    imgHeight: 100, 
    imgWidth: 109,
    strprice: 'price_1JNOvXLQUQlbt1749DrEN9pU',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: ['K1Rpg4S2o5Ql3e-2.png', 'K1Rpg4S2o5Ql3e-3.png'],
  },
  {
    id: 'K1TyjPAz7icpmK',
    name: 'Limited Overs Playing Shirt (LS)',
    img: 'png',
    desc: 'Long sleeved navy and red limited overs playing shirt, worn by the First XI in 40 over and T20 formats',
    price: 6050,
    imgHeight: 100, 
    imgWidth: 109,
    strprice: 'price_1JNR0hLQUQlbt174aPwJYCFL',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: ['K1TyjPAz7icpmK-2.png', 'K1TyjPAz7icpmK-3.png'],
  },
  {
    id: 'K1TAEnMIVUXc0d',
    name: 'Limited Overs Playing Trousers',
    img: 'png',
    desc: 'Navy and red limited overs playing trousers, worn by the First XI in 40 over and T20 formats',
    price: 5500,
    imgHeight: 100, 
    imgWidth: 109,
    strprice: 'price_1JNQE3LQUQlbt1746YXKw4vV',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: ['K1TAEnMIVUXc0d-2.png', 'K1TAEnMIVUXc0d-3.png', 'K1TAEnMIVUXc0d-4.png'],
  },
  {
    id: 'K1TPM2Qrzz4SYb',
    name: 'Club Baseball Cap',
    img: 'png',
    desc: 'Navy and red Titans baseball cap, worn by the First XI in 40 over and T20 formats',
    price: 2500,
    imgHeight: 100, 
    imgWidth: 160,
    strprice: 'price_1JNQSDLQUQlbt174jrdEFXvP',
    sizes: ['Adj', 'Fixed'],
    imgs: ['K1TPM2Qrzz4SYb-2.png', 'K1TPM2Qrzz4SYb-3.png', 'K1TPM2Qrzz4SYb-4.png'],
  },
  {
    id: 'K1ToCdzCK0Z1VM',
    name: 'Cream Playing Shirt (SS)',
    img: 'png',
    desc: 'Short sleeved cream playing shirt, worn by the club\'s senior mens teams',
    price: 5500,
    imgHeight: 100, 
    imgWidth: 109,
    strprice: 'price_1JNQqZLQUQlbt174KVCkkVRD',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL', 'XXXXXL'],
    imgs: ['K1ToCdzCK0Z1VM-2.png', 'K1ToCdzCK0Z1VM-3.png', 'K1ToCdzCK0Z1VM-4.png'],
  },
  {
    id: 'K1UBmZ7h1tLQDJ',
    name: 'Cream Playing Shirt (LS)',
    img: 'png',
    desc: 'Long sleeved cream playing shirt, worn by the club\'s senior mens teams',
    price: 6050,
    imgHeight: 100, 
    imgWidth: 90,
    strprice: 'price_1JNRD2LQUQlbt174GCfmcWe8',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL', 'XXXXXL'],
    imgs: ['K1UBmZ7h1tLQDJ-2.png', 'K1UBmZ7h1tLQDJ-3.png', 'K1UBmZ7h1tLQDJ-4.png'],
  },
  {
    id: 'K1TvNgv5VSzt43',
    name: 'Cream Playing Trousers',
    img: 'png',
    desc: 'Cream playing trousers, worn by the club\'s senior mens teams',
    price: 5500,
    imgHeight: 100, 
    imgWidth: 90,
    strprice: 'price_1JNQxJLQUQlbt174oB3nt10b',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: ['K1TvNgv5VSzt43-2.png', 'K1TvNgv5VSzt43-3.png', 'K1TvNgv5VSzt43-4.png'],
  },
  {
    id: 'K1UGN0lPRIXNkp',
    name: 'Cream Playing Vest',
    img: 'png',
    desc: 'Cream playing vest, worn by the club\'s senior mens teams',
    price: 7700,
    imgHeight: 100, 
    imgWidth: 80,
    strprice: 'price_1JNRIGLQUQlbt174CrfufNck',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: [],
  },
  {
    id: 'K1UI9wnzeSPzKU',
    name: 'Cream Playing Jumper',
    img: 'png',
    desc: 'Cream playing jumper, worn by the club\'s senior mens teams',
    price: 8250,
    imgHeight: 100, 
    imgWidth: 90,
    strprice: 'price_1JNRJhLQUQlbt174O92kevDN',
    sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
    imgs: [],
  },
];

function App() {
  const [cookies, setCookie] = useCookies(['cart']);

  const [cart, setCart] = useState(cookies.cart ? cookies.cart : { products: 0, total: 0, units: 0 });
  const [cartVisible, setCartVisible] = useState(false);
  const [showSuccess, setShowSuccess] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [fetchingOrderDetails, setFetchingOrderDetails] = useState(false);
  const [largeImage, setLargeImage] = useState(0);

  const successPage = (window.location.pathname === '/success');
  const sizingPage = (window.location.pathname === '/sizing');

  const productPage = (!successPage && !sizingPage && window.location.pathname.length > 2);
  const viewingProduct = productPage ?  window.location.pathname.substring(1, window.location.pathname.length) : '';
  const liveProduct = productPage ? products.filter((pr) => pr.id === viewingProduct)[0] : null;
  const productImageGallery = productPage ? [ `${liveProduct.id}.${liveProduct.img}`].concat(liveProduct.imgs) : null;

  const addToCart = (id, vol = 1, sz) => {
    const newC = JSON.parse(JSON.stringify(cart));
    const size = products.filter((pr) => pr.id === id)[0].sizes[sz];

    if (!newC[id]) {
      newC[id] = {};
      newC[id][size] = { no: 1 };
      newC.units += 1;
    } else {
      if (!newC[id][size]) {
        newC[id][size] = { no: 1 };
        newC.units += 1;
      } else {
        newC[id][size].no += vol;
      }
    }

    if (newC[id][size].no === 0) {
      delete newC[id][size];
      newC.units -= 1;
    }

    if (Object.keys(newC[id]).length === 0) {
      delete newC[id];
    }

    newC.total += vol * products.filter((pr) => pr.id === id)[0].price
    newC.products += vol;

    setCookie('cart', JSON.stringify(newC), { path: '/' });
    setCart(newC);
    setCartVisible(true);
  };

  const clearCart = () => {
    setCookie('cart', JSON.stringify({ products: 0, total: 0, units: 0 }), { path: '/' });
    setCart({ products: 0, total: 0, units: 0 });
  }

  const handleSelect = (e) => {
    addToCart(liveProduct.id, 1, e);
  }

  if (successPage && cart.products !== 0 && showSuccess) {
    clearCart();
  }

  if (successPage && !fetchingOrderDetails) {
    setFetchingOrderDetails(true);

    fetch(`${server}/order/success${window.location.search}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setOrderDetails(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <CookiesProvider>
      <Container className="App">
        <TopBanner { ...{ cart, cartVisible, setCartVisible } } />
        <Cart { ...{ cart, cartVisible, setCartVisible, addToCart, clearCart }} />

        <div style={{ paddingTop: 137, minHeight: 'calc(100vh - 100px)' }}> 
          {successPage && showSuccess && (
            <Alert variant="primary" dismissible transition onClose={() => setShowSuccess(false)} style={{ marginTop: '2rem', textAlign: 'left' }}>
              {!orderDetails && <i className="fas fa-circle-notch fa-spin" />}
              {orderDetails && (
                <span>
                  Thank you
                  {' '}
                  {orderDetails.name}
                  {' '}
                  for your order of $
                  {(orderDetails.amount / 100).toFixed(2)}
                  . It has been successfully processed and a receipt will be emailed to you at
                  {' '}
                  {orderDetails.email}
                  .
                </span>
              )}
            </Alert>
          )}
          {successPage && showSuccess && (
            <Container fluid style={{ height: 300, background: 'no-repeat center/contain url(/img/titan.png)'}}>
              &nbsp;
            </Container>
          )}
          {successPage && showSuccess && orderDetails && (
            <Button variant="primary" style={{ marginTop: '1em', background: '#2d3290' }} onClick={() => window.location.href = '/' }>Return to homepage</Button>
          )}
          {sizingPage && (
            <Row>
              <Col>
                <h4 style={{ marginBottom: '1.25em', marginTop: '1.25em' }}>Sizing</h4>
                <p>Use the sizing charts below to determine the appropriate garment size for you.</p>
                <p><img src={sizingOne} alt="Sizing chart 1" style={{ maxHeight: 300, maxWidth: '100%' }} /></p>
                <p><img src={sizingTwo} alt="Sizing chart 2" style={{ maxHeight: 300, maxWidth: '100%' }} /></p>
                <Button variant="primary" style={{ marginTop: '1em', background: '#2d3290' }} onClick={() => window.location.href = '/' }>Return to homepage</Button>
              </Col>
            </Row>
          )}
          {productPage && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h4 style={{ marginBottom: '1.25em', marginTop: '1.25em' }}>
                      View product:&nbsp;
                      {liveProduct.name}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col className="d-flex align-items-center" style={{ height: 240, width: '100%', justifyContent: 'center' }}>
                        <div className="d-flex align-items-center" style={{ height: 230, width: 350, border: '1px black solid' }}>
                          <img
                            src={`/img/products/${productImageGallery[largeImage]}`}
                            style={{ height: 200, width: 'auto', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                            alt="Live product"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '0.5rem' }}>
                      <Col>
                        {
                          productImageGallery.map((productImage, index) => (
                            <div
                              style={{ display: 'inline-block', cursor: 'pointer', margin: 3, border: '1px black solid' }}
                              onClick={() => setLargeImage(index)}
                            >
                              <img
                                src={`/img/products/${productImage}`}
                                style={{ maxHeight: 33, alignSelf: 'center', margin: '1em' }}
                                alt={`Live product ${index}`}
                              />
                            </div>
                          ))
                        }
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ textAlign: 'left' }}>
                    <Row style={{ marginBottom: '0.5rem' }}>
                      <Col>
                        <strong>Description:</strong>
                        <br />
                        {liveProduct.desc}
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '1rem' }}>
                      <Col>
                        <strong>Price:</strong>
                        <br />
                        $
                        {(liveProduct.price / 100).toFixed(2)}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DropdownButton id="dropdown-basic-button" title="Add to cart" onSelect={handleSelect}>
                          {liveProduct.sizes.map((size, index) => <Dropdown.Item key={`product-${index}`} eventKey={index}>{size}</Dropdown.Item>)}
                        </DropdownButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ fontSize: '80%', marginTop: '0.25rem' }}>
                        <a href="/sizing">Sizing guide</a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="primary" style={{ marginTop: '1em', background: '#2d3290' }} onClick={() => window.location.href = '/' }>Return to homepage</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {!sizingPage && (!successPage || !showSuccess) && !productPage && (
            <Row>
              <Col style={{ marginBottom: ' 1.25em' }}>
                <h4 style={{ marginBottom: '1.25em', marginTop: '1.25em' }}>Senior men's apparel</h4>
                <p>Join the Titan Army with our range of playing apparel available for purchase through our secure portal, powered by <a href="https://www.stripe.com/au">Stripe</a>.</p>
                <p><a href="/sizing">Sizing charts</a> are available to assist with shirt and trouser orders. Cap orders will be sized individually.</p>
              </Col>
            </Row>
          )}
          {!sizingPage && (!successPage || !showSuccess) && !productPage && (
            <Row>
              {products.map((product) => (
                <EMTCCProduct { ...{ product, addToCart } } key={product.id} />  
              ))}
            </Row>
          )}
        </div>
        
      </Container>
      <Footer />
    </CookiesProvider>
  );
}

const TopBanner = ({ cart, cartVisible, setCartVisible }) => {
  function toggleCartVisible() {
    setCartVisible(!cartVisible);
  }
  return (
    <header className="container" style={{ position: 'fixed', top: 0, width: '100%', marginRight: 'auto', marginLeft: 'auto', background: 'white', zIndex: 999 }}>
      <Row className="border-bottom p-3">
        <Col className="text-left" style={{ textAlign: 'left' }}>
          <Row>
            <Col xs="auto" style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/' } >
              <img src={logo} style={{ maxHeight: '96px', maxWidth: '100%' }} alt="EMTCC logo" className="my-1 mx-2" />
            </Col>
            <Col>
              <div className="h-100 d-flex align-items-center">
                <h3 className="d-none d-md-block">East Malvern Tooronga Cricket Club</h3>
                <h4 className="d-block d-md-none">East Malvern Tooronga Cricket Club</h4>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="auto" className="d-flex align-items-center text-center">
          <span className="fa-stack" style={{ cursor: 'pointer' }} role="button" onClick={() => toggleCartVisible()}>
            <i className="fas fa-shopping-cart fa-stack-2x" />
            <span
              className="fa-stack-1x text-white"
              style={{ marginTop: '-4.5px', fontWeight: 'bold', marginLeft: '2px', fontSize: '66%' }}
            >
              {cart.products}
            </span>
          </span>
        </Col>
      </Row>
    </header>
  );
};

const Cart = ({ cart, cartVisible, setCartVisible, addToCart, clearCart }) => {
  const klass = cartVisible ? 'd-block' : 'd-none';
  const barH = cart.products > 0 ? 186 : 275;

  return (
    <div
      className={klass}
      style={{ position: 'fixed', top: '100px', right: '50px', width: '325px', zIndex: 1000 }}
      onClick={(event) => { event.preventDefault(); event.stopPropagation(); return false; }}
    >
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ position: 'absolute', top: 15, right: 20, zIndex: 2000 }}
        onClick={() => setCartVisible(false)}
      />
      <Card>
        <SimpleBar forceVisible="y" autoHide={true} style={{ height: barH, width: '100%' }}>
          <Card.Body>
            {cart.products === 0 && (
              <Row>
                <Col>There are no items in your shopping cart</Col>
              </Row>
            )}
            {cart.products > 0 && (
              Object.keys(cart).map((item) => {
                //if (item === 'products' || item === 'total' || item === 'units') {
                if (['products', 'total', 'units'].indexOf(item) !== -1) {
                  return null;
                }

                const sku = products.filter((pr) => pr.id === item)[0];

                return Object.keys(cart[item]).map((sz) => {
                  return (
                    <Row
                      className="mx-1"
                      style={{ marginBottom: '1em', paddingBottom: '1em', borderBottom: '1px solid #c5c5c5' }}
                      key={`${item}-${sz}`}
                    >
                      <Col xs="auto">
                        <img
                          src={`/img/products/${sku.id}.${sku.img}`}
                          style={{ height: (sku.imgHeight/4), width: (sku.imgWidth/4), alignSelf: 'center' }}
                          alt={sku.name}
                        />
                      </Col>
                      <Col className="d-flex align-items-center" style={{ fontSize: '75%', textAlign: 'left' }}>
                        {sku.name}
                        :&nbsp;
                        {sz}
                      </Col>
                      <Col xs="auto">
                        <CartQuantity quantity={cart[item][sz].no} addToCart={addToCart} sku={item} sz={sz} />
                      </Col>
                    </Row>
                  )
                });
              }))
            }
          </Card.Body>
        </SimpleBar>
        {cart.products > 0 && (
          <Card.Footer style={{ background: 'none', border: 'none', paddingTop: 0 }}>
            <Row>
              <Col style={{ textAlign: 'left', fontSize: '75%', marginBottom: '7px' }}>
                <small>
                  <strong>Products: </strong>
                  {cart.units}
                  <br />
                  <strong>Units: </strong>
                  {cart.products}
                </small>
              </Col>
              <Col style={{ textAlign: 'right' }}>
                <small>
                  <strong>Total: </strong>
                  ${(cart.total / 100).toFixed(2)}
                </small>
              </Col>
            </Row>
            <Row>
              <Col className="px-1">
                <Button variant="secondary" className="w-100" onClick={() => clearCart()}>Clear Cart</Button>
              </Col>
              <Col className="px-1">
                <form id="checkout-form" action={`${server}/create-checkout-session`} method="POST">
                  <input type="hidden" name="cart" value={JSON.stringify(cart).toString()} />
                  <Button variant="primary" className="w-100" onClick={() => document.getElementById("checkout-form").submit() }>Checkout</Button>
                </form>
              </Col>
            </Row>
          </Card.Footer>
        )}
      </Card>
    </div>
  );
};

const CartQuantity = ({ sku, quantity, addToCart, sz}) => (
  <div className="d-flex align-items-center h-100">
    <div
      className="p-1 d-flex align-items-center"
      style={{ borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%', border: '1px #c5c5c5 solid', fontSize: '60%', height: 20, color: '#757575', cursor: 'pointer' }}
      onClick={() => addToCart(sku, -1, products.filter((pr) => pr.id === sku)[0].sizes.indexOf(sz))}
    >
      <i className="fas fa-minus" />
    </div>
    <div
      className="px-2 d-flex align-items-center"
      style={{ height: 20, borderTop: '1px #c5c5c5 solid', borderBottom: '1px #c5c5c5 solid', fontSize: '70%' }}
    >
      {quantity}
    </div>
    <div
      className="p-1 d-flex align-items-center"
      style={{ borderTopRightRadius: '50%', borderBottomRightRadius: '50%', border: '1px #c5c5c5 solid', fontSize: '60%', height: 20, color: '#757575', cursor: 'pointer' }}
      onClick={() => addToCart(sku, 1, products.filter((pr) => pr.id === sku)[0].sizes.indexOf(sz))}
    >
      <i className="fas fa-plus" />
    </div>
  </div>
);

const EMTCCProduct = ({ product, addToCart }) => {
  const handleSelect = (e) => {
    console.log(e);
    addToCart(product.id, 1, e);
  }

  return (
    <Col lg="4" md="6" sm="12" style={{ marginBottom: '1em' }} className="d-flex align-items-stretch" key={product.id}>
      <Card className="text-center" style={{ borderRadius: '10px', paddingTop: '1em', width: '100%' }}>
        <Card.Img
          variant="top"
          src={`/img/products/${product.id}.${product.img}`}
          style={{ height: product.imgHeight, width: product.imgWidth, alignSelf: 'center' }}
          className="my-1"
        />
        <Card.Body style={{ textAlign: 'left' }}>
          <Card.Title>{product.name}</Card.Title>
          <Card.Text>
            ${(product.price/100).toFixed(2)}
            <br />
            <small>{product.desc}</small>
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{ background: 'none', border: 'none', paddingTop: 0 }}>
          <Row>
            <Col className="px-1">
              <a href={`/${product.id}`} className="btn btn-secondary w-100">More details</a>
            </Col>
            <Col className="px-1">
              <DropdownButton id="dropdown-basic-button" title="Add to cart" className="w-100" onSelect={handleSelect}>
                {product.sizes.map((size, index) => <Dropdown.Item key={`product-${index}`} eventKey={index}>{size}</Dropdown.Item>)}
              </DropdownButton>
              
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Col>
  );
};

const Footer = () => {
  console.log('Footer');
  return (
    <Container fluid style={{ background: '#2d3290', color: 'white', minHeight: 100, borderTop: '0.05rem solid #e5e5e5', marginTop: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
      <Container>
        <Row className="d-flex align-items-center">
          <Col style={{ paddingLeft: '2rem', fontSize: '80%' }}>
            <Row>
              <Col><strong>East Malvern Tooronga Cricket Club</strong></Col>
            </Row>
            <Row>
              <Col>DW Lucas Reserve, Dunlop St, East Malvern VIC 3145</Col>
            </Row>
          </Col>
          <Col className="text-center" sm="auto" style={{ fontSize: '75%', padding: '0.5rem', paddingRight: '1rem' }}>
            <img src={rocrt} alt="Rocrt logo" style={{ height: 50 }} />
            <br />
            Powered by Rocrt
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default App;
